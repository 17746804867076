@font-face {
  font-family: 'Montserrat-Bold';
  src: url('./assets/fonts/static/Montserrat-Bold.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-BoldItalic';
  src: url('./assets/fonts/static/Montserrat-BoldItalic.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-ExtraBold';
  src: url('./assets/fonts/static/Montserrat-ExtraBold.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-ExtraBoldItalic';
  src: url('./assets/fonts/static/Montserrat-ExtraBoldItalic.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

/* @font-face {
  font-family: 'HCLTechRoobert-Heavy';
  src: url('./assets/fonts/static/HCLTechRoobert-Heavy.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
} */

/* @font-face {
  font-family: 'HCLTechRoobert-HeavyItalic';
  src: url('./assets/fonts/static/HCLTechRoobert-HeavyItalic.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
} */

@font-face {
  font-family: 'Montserrat-Light';
  src: url('./assets/fonts/static/Montserrat-Light.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-LightItalic';
  src: url('./assets/fonts/static/Montserrat-LightItalic.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: url('./assets/fonts/static/Montserrat-Medium.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-MediumItalic';
  src: url('./assets/fonts/static/Montserrat-MediumItalic.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: url('./assets/fonts/static/Montserrat-Regular.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url('./assets/fonts/static/Montserrat-SemiBold.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-SemiBoldItalic';
  src: url('./assets/fonts/static/Montserrat-SemiBoldItalic.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

/* @font-face {
  font-family: 'HCLTechRoobert-Regular';
  src: url('./assets/fonts/static/HCLTechRoobert-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
} */

* {
  font-family: 'Montserrat-Regular' !important;
  /* Use a fallback font like 'sans-serif' in case the custom font fails to load */
}
/* Chart CSS */

/* width: 600px !important;
height: 525px !important; */
/* #polarChart , #polarChart canvas{
  min-width: 200px !important;
  max-width: 700px !important;
} */

.background-body{
  /* background: linear-gradient(80deg, #B9C8FF, #DCE6F0, #8CC8FA, #DCE6F0); */
  background:  rgba(100, 122, 163, 0.3);
}

.background-body-home{
  background: linear-gradient(80deg, #5F1EBE,#8C69F0,#0F5FDC,#3C91FF, #8CC8FA);
}

/* @media only screen and (min-width: 576px) { */
  #polarChart {
    margin-top: -5rem;
  }
/* } */

@media only screen and (max-width: 576px) {
  /* #polarChart {
    min-width: 900px;
  } */
  #chartContainer {
    /* min-width: 500px !important;
    max-height: 500px !important; */
    scale: 0.8;
  }
}

/* ::-webkit-scrollbar {
  width: 8px;
  border-radius: 10px;
  background: #A5AFBE;
} */

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  /* background: #A5AFBE;  */
  background: rgba(0, 0, 0, 1);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #89919e; 
}


@font-face {
  font-family: 'Raleway-Bold';
  src: url('./assets/fonts/static/Raleway-Bold.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway-BoldItalic';
  src: url('./assets/fonts/static/Raleway-BoldItalic.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway-ExtraBold';
  src: url('./assets/fonts/static/Raleway-ExtraBold.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway-ExtraBoldItalic';
  src: url('./assets/fonts/static/Raleway-ExtraBoldItalic.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Raleway-Light';
  src: url('./assets/fonts/static/Raleway-Light.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway-LightItalic';
  src: url('./assets/fonts/static/Raleway-LightItalic.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway-Medium';
  src: url('./assets/fonts/static/Raleway-Medium.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway-MediumItalic';
  src: url('./assets/fonts/static/Raleway-MediumItalic.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway-Regular';
  src: url('./assets/fonts/static/Raleway-Regular.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway-SemiBold';
  src: url('./assets/fonts/static/Raleway-SemiBold.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway-SemiBoldItalic';
  src: url('./assets/fonts/static/Raleway-SemiBoldItalic.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}


#hideScrollBar .MuiTableContainer-root::-webkit-scrollbar {
  display: none;
  width: 0px;
  background-color: transparent;
}