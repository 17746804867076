.sticky{
    position: sticky;
    left: 0;
    background: white;
    z-index: 3;
}

tr td{
    padding: 8px !important;

}

tr td div{
    padding: 4px 8px;
}

tr td:first-child{
    background: #DCE6F0;
    font-size: 14px;
    font-weight: 600;
}

tr td:not(:first-child) div{
    font-size: 12px;
}

tr th{
    font-size: 14px;
}